<template>
  <layout class="menu-container">
    <Row :gutter="16" style="height: 100%">
      <Col
        style="
          border-right: 1px #eee solid;
          overflow-x: hidden;
          overflow-y: auto;
          height: 100%;
        "
        span="6"
      >
        <Tree :data="moduleList" />
      </Col>
      <Col
        span="17"
        style="
          padding: 20px;
          border-right: 1px #eee solid;
          overflow-x: hidden;
          overflow-y: auto;
          height: 100%;
        "
      >
        <Form label-colon v-if="selectedObj.ModID || isNew" :label-width="120">
          <FormItem label="父级菜单编号">
            <Input readonly v-model="selectedObj.PModuleID" />
          </FormItem>
          <FormItem label="父级菜单名称">
            <Input readonly v-model="selectedObj.PModuleName" />
          </FormItem>
          <FormItem label="菜单编号">
            <Input readonly v-model="selectedObj.ModID" />
          </FormItem>
          <FormItem label="菜单等级">
            <Input readonly v-model="selectedObj.ModuleLevel" />
          </FormItem>
          <FormItem label="菜单名称">
            <Input readonly v-model="selectedObj.ModuleTitle" />
          </FormItem>
          <FormItem label="菜单缩写">
            <Input readonly v-model="selectedObj.ModuleShort" />
          </FormItem>
          <FormItem label="菜单图标">
            <Input readonly v-model="selectedObj.IconFile" />
          </FormItem>
          <FormItem label="菜单连接">
            <Input readonly v-model="selectedObj.LinkURL" />
          </FormItem>
          <FormItem label="是否隐藏">
            <Input
              readonly
              :value="selectedObj.HiddenTag === 10 ? '显示' : '隐藏'"
            />
          </FormItem>
          <FormItem label="排序参数">
            <Input readonly v-model="selectedObj.Index" />
          </FormItem>
          <FormItem label="菜单描述">
            <Input readonly v-model="selectedObj.ModuleDesc" />
          </FormItem>
          <FormItem>
            <Button
              type="success"
              icon="md-add-circle"
              v-action:Sys_Mod_Edit
              @click="handleAddNew"
              >新增</Button
            >
            <Button
              style="margin-left: 8px"
              type="primary"
              icon="md-brush"
              v-action:Sys_Mod_Edit
              v-if="selectedObj.ModID"
              @click="onEdit"
              >编辑</Button
            >
            <Button
              style="margin-left: 8px"
              icon="md-trash"
              type="error"
              v-action:Sys_Mod_Del
              v-if="selectedObj.ModID"
              @click="handleOpenDelete"
              >删除</Button
            >
          </FormItem>
        </Form>
      </Col>
    </Row>

    <Modal v-model="showMenuView" title="编辑菜单信息" :width="800">
      <Form
        label-colon
        ref="menuForm"
        :model="menuForm"
        :rules="rules"
        :label-width="120"
      >
        <FormItem label="父级菜单编号">
          <Input readonly v-model="menuForm.PModuleID" />
        </FormItem>
        <FormItem label="父级菜单名称">
          <Input readonly v-model="menuForm.PModuleName" />
        </FormItem>
        <FormItem label="菜单编号">
          <Input readonly v-model="menuForm.ModID" />
        </FormItem>
        <FormItem label="菜单等级">
          <Input readonly v-model="menuForm.ModuleLevel" />
        </FormItem>
        <FormItem label="菜单名称" prop="ModuleTitle">
          <Input v-model="menuForm.ModuleTitle" />
        </FormItem>
        <FormItem label="菜单缩写" prop="ModuleShort">
          <Input v-model="menuForm.ModuleShort" />
        </FormItem>
        <FormItem label="菜单图标">
          <Input v-model="menuForm.IconFile" />
        </FormItem>
        <FormItem label="菜单连接">
          <Input v-model="menuForm.LinkURL" />
        </FormItem>
        <FormItem label="是否隐藏">
          <Select transfer v-model="menuForm.HiddenTag">
            <Option :value="10">显示</Option>
            <Option :value="20">隐藏</Option>
          </Select>
        </FormItem>
        <FormItem label="排序参数">
          <Input v-model="menuForm.Index" />
        </FormItem>
        <FormItem label="菜单描述">
          <Input v-model="menuForm.ModuleDesc" />
        </FormItem>
      </Form>
      <template #footer>
        <Button @click="showMenuView = false">取消</Button>
        <Button type="primary" @click="onSave">保存</Button>
      </template>
    </Modal>
  </layout>
</template>

<script>
import api from "@/api";
import layout from "@/components/layout/index.vue";
import { delayFunc } from "@/utils";
export default {
  name: "MenuManage",
  components: { layout },
  data() {
    this.rules = {
      ModuleTitle: [
        { required: true, message: "菜单名称不能为空", trigger: "blur" },
      ],
      ModuleShort: [
        { required: true, message: "菜单缩写不能为空", trigger: "blur" },
      ],
    };
    return {
      isNew: false,
      moduleList: [],
      selectedObj: {},
      showMenuView: false,
      menuForm: {
        PModuleID: "",
        PModuleName: "",
        ModID: "",
        ModuleLevel: "",
        ModuleTitle: "",
        ModuleShort: "",
        IconFile: "",
        LinkURL: "",
        HiddenTag: "",
        Index: "",
        ModuleDesc: "",
      },
    };
  },
  created() {
    this.getModuleList();
  },
  mounted() {
    this.$bus.$on("refreshPage", () => {
      this.moduleList = [];
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.getModuleList();
      }
    });
  },
  methods: {
    renderContent(h, { data }) {
      return h(
        "div",
        {
          class: {
            "tree-content": true,
          },
          style: {
            background:
              data.data.ModID === this.selectedObj.ModID
                ? "#5cadff"
                : "#ffffff",
            display: "inline-block",
            width: "100%",
            height: "24px",
            lineHeight: "24px",
            cursor: "pointer",
          },
          on: {
            click: () => {
              this.handleTreeItem(data.data);
            },
          },
        },
        [
          h(
            "span",
            {
              style: {
                marginLeft: "10px",
                color:
                  data.data.ModID === this.selectedObj.ModID
                    ? "#fff"
                    : "#17233d",
                cursor: "pointer",
              },
            },
            data.title
          ),
        ]
      );
    },
    handleTreeItem(row) {
      this.selectedObj = row;
      this.selectedObj.PModuleName = row.ModuleTitle;
    },
    onSave() {
      this.$refs.menuForm.validate(async (valid) => {
        if (valid) {
          try {
            if (this.menuForm.ModuleLevel > 3)
              return this.$Message.error("三级菜单不允许有下级菜单");
            await api.SaveModule({ entity: this.menuForm });
            this.$Message.success("保存成功");
            this.showMenuView = false;
            await delayFunc();
            this.getModuleList();
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
          }
        }
      });
    },
    onEdit() {
      this.$refs.menuForm.resetFields();
      this.menuForm = { ...this.selectedObj };
      this.showMenuView = true;
    },
    handleOpenDelete() {
      const _this = this;
      if (this.selectedObj.IsAdd) {
        this.$Message.error("请先保存新增的菜单");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        closable: true,
        loading: true,
        content: "确定删除菜单【" + _this.selectedObj.ModuleTitle + "】吗？",
        onOk: function () {
          _this.handleDelete();
        },
        onCancel: () => {
          _this.$Modal.remove();
        },
      });
    },
    async handleDelete() {
      try {
        const res = await api.DeleteModule({
          id: this.selectedObj.ModID,
        });
        if (res.Status === 100) {
          this.$Message.success("删除成功");
          this.$Modal.remove();
          await delayFunc();
          this.getModuleList();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Modal.remove();
      }
    },

    async handleAddNew() {
      this.$refs.menuForm.resetFields();
      this.isNew = true;
      try {
        const temp = this.selectedObj;
        const res = await api.GenerateSystemCode({
          strOperationCode: "ModuleCode",
        });
        this.menuForm = {
          ...this.menuForm,
          ModID: res.Data,
          ModuleLevel: temp.ModuleLevel + 1,
          PModuleID: temp.ModID,
          PModuleName: temp.PModuleName,
          IsAdd: true,
        };
        this.showMenuView = true;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },

    async getModuleList() {
      const res = await api.GetAllModuleList();
      const modules = {
        Module0: [],
        Module1: [],
        Module2: [],
        Module3: [],
      };

      res.Data.forEach((item) => {
        modules[`Module${item.ModuleLevel}`].push({
          data: item,
          title: item.ModuleTitle,
          expand: true,
          children: [],
          render: this.renderContent,
        });
      });
      modules.Module2.forEach((cell) => {
        cell.children = modules.Module3.filter(
          (item) => item.data.PModuleID === cell.data.ModID
        );
      });
      modules.Module1.forEach((cell) => {
        cell.children = modules.Module2.filter(
          (item) => item.data.PModuleID === cell.data.ModID
        );
      });
      modules.Module0[0].children = modules.Module1;
      this.moduleList = modules.Module0;
      if (this.moduleList[0]) {
        this.handleTreeItem(this.moduleList[0].data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.menu-container {
  /deep/.ivu-tree-title {
    width: 100%;
    padding: 0;
    &.ivu-tree-title-selected {
      background-color: rgb(92, 173, 255);
    }
  }
}
</style>
